import Image from 'next/image';
import { ReactNode } from 'react';
import styled from 'styled-components';

import { ModalTheme } from '.';
import { boxElevation } from '@/styles/mixin';
import { color, devices } from '@/styles/variables';
import imageLoader from '@/utils/loader';

const Content = styled.div<{ theme: ModalTheme; mobile: boolean }>`
	${props => props.theme === ModalTheme.Primary && boxElevation(8, color.primaryYellow)}
	align-items: center;
	background-color: ${color.whiteColor};
	border: solid 1px ${color.blackColor};
	border-radius: 1px;
	display: ${props => (props.mobile ? 'none' : 'flex')};
	justify-content: center;
	max-height: 90vh;
	max-width: 90vw;
	position: relative;
	&:focus {
		outline: none;
	}
	@media ${devices.laptop} {
		display: flex;
		overflow: unset;
	}
`;
const Header = styled.div`
	align-items: center;
	display: flex;
	height: 20px;
	justify-content: end;
	max-width: 100vw;
	position: absolute;
	right: -42px;
	top: -42px;
	width: calc(100% + 42px * 2);
`;
const HeaderContent = styled.div`
	width: 100%;
`;
const ContentHeader = styled.div`
	align-items: center;
	background-color: ${color.primaryYellow};
	border-bottom: solid 1px #000;
	display: flex;
	gap: 14px;
	height: 38px;
	padding-left: 32px;
	padding-right: 32px;
`;
const HeaderRadioOne = styled.div`
	background-color: #ffd3d3;
	border: solid 1px #000;
	border-radius: 50%;
	height: 10px;
	width: 10px;
`;
const HeaderRadioTwo = styled.div`
	background-color: #fdecc6;
	border: solid 1px #000;
	border-radius: 50%;
	height: 10px;
	width: 10px;
`;
const HeaderRadioThree = styled.div`
	background-color: #eafec6;
	border: solid 1px #000;
	border-radius: 50%;
	height: 10px;
	width: 10px;
`;
const ModalClose = styled.div`
	background-color: ${color.whiteColor};
	border: solid 1px ${color.blackColor};
	border-radius: 1px;
	cursor: pointer;
	display: none;
	padding: 8px 14px;
	position: absolute;
	right: 0;
	top: 0;
	@media ${devices.tablet} {
		display: inline-block;
	}
`;
const ModalCloseMobile = styled.div`
	background-color: ${color.whiteColor};
	border: solid 1px ${color.blackColor};
	border-radius: 1px;
	bottom: -56px;
	display: inline-block;
	padding: 8px 14px;
	position: absolute;
	@media ${devices.tablet} {
		display: none;
	}
`;
const ContentContainer = styled.div<{ theme: ModalTheme }>`
	width: 100%;
`;
const ContentContent = styled.div`
	max-height: 90vh;
	overflow-y: auto;
	width: 100%;
`;

const ModalContainer = ({
	mobile,
	title,
	children,
	handleClose,
	theme
}: {
	mobile: boolean;
	title?: string;
	children: ReactNode;
	handleClose: () => void;
	theme?: ModalTheme;
}) => {
	return (
		<Content mobile={mobile}>
			<Header>
				<HeaderContent>{title}</HeaderContent>
				<ModalClose onClick={handleClose}>
					<Image
						src="/icon/close.svg"
						alt="close"
						width={12}
						height={12}
						unoptimized
						loader={imageLoader}
					/>
				</ModalClose>
			</Header>
			<ContentContainer theme={theme}>
				{theme === ModalTheme.Primary && (
					<ContentHeader>
						<HeaderRadioOne />
						<HeaderRadioTwo />
						<HeaderRadioThree />
					</ContentHeader>
				)}
				<ContentContent>{children}</ContentContent>
			</ContentContainer>
			<ModalCloseMobile onClick={handleClose}>
				<Image
					src="/icon/close.svg"
					alt="close"
					width={12}
					height={12}
					unoptimized
					loader={imageLoader}
				/>
			</ModalCloseMobile>
		</Content>
	);
};

export default ModalContainer;
