import React from 'react';
import styled from 'styled-components';

import { BadgeZhNameAndColorMap } from '@/constants/careerCommunity';
import { color } from '@/styles/variables';

const { whiteColor, blackColor, primaryColor } = color;

const Container = styled.div`
	background-color: ${props => props.theme.bgColor || primaryColor};
	border: 1px solid ${blackColor};
	color: ${props => (props.theme.bgColor === primaryColor && whiteColor) || blackColor};
	font-size: 12px;
	height: 22px;
	line-height: 12px;
	padding: 3px;
	white-space: nowrap;
`;

const Badge = ({ badgeName }: { badgeName: string }) => {
	if (!badgeName) return null;
	const zhName = BadgeZhNameAndColorMap[badgeName]?.zhName || '';
	const bgColor = BadgeZhNameAndColorMap[badgeName]?.bgColor || '';
	return <Container theme={{ bgColor }}>{zhName}</Container>;
};

export default Badge;
