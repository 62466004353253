import Image from 'next/image';
import Link from 'next/link';
import { ReactNode } from 'react';
import styled from 'styled-components';

import Button from '@/components/UI/Button';
import { boxElevation, pageContainer } from '@/styles/mixin';
import { color, devices } from '@/styles/variables';
import imageLoader from '@/utils/loader';

type Theme = 'default' | 'light' | 'dark';
const getBgColor = (theme: Theme) => {
	switch (theme) {
		case 'light':
			return color.primaryLightYellow;
		case 'dark':
			return color.primaryDark;
		case 'default':
		default:
			return color.bgColor;
	}
};
const getColor = (theme: Theme) => {
	switch (theme) {
		case 'dark':
			return color.whiteColor;
		case 'light':
		case 'default':
		default:
			return color.blackColor;
	}
};
type StyledProps = { theme: Theme };
const SectionContainer = styled.div<StyledProps>`
	background-color: ${props => getBgColor(props.theme)};
	color: ${props => getColor(props.theme)};
	width: 100%;
`;
const Section = styled.div<StyledProps>`
	${pageContainer('1440px')};
	padding: 32px 16px;
	position: relative;
	z-index: 1;
	@media ${devices.tablet} {
		padding: 48px 24px;
	}
	@media ${devices.largeLaptop} {
		padding: 80px 0;
	}
`;

const ImageContainer = styled.div`
	display: none;
	position: absolute;
	right: 0;
	top: 80px;
	z-index: -1;
	@media ${devices.mobile} {
		display: block;
	}
`;

const TitleContainer = styled.div`
	align-items: center;
	display: flex;
	gap: 16px;
`;

const Title = styled.h2`
	font-size: 20px;
	font-weight: 600;
	gap: 8px;
	margin: 0 0;
	@media ${devices.tablet} {
		font-size: 26px;
	}
`;

const Hot = styled.div`
	background-color: ${color.primaryColor};
	border: solid 1px #000;
	border-radius: 1px;
	color: ${color.whiteColor};
	${boxElevation(2, color.whiteColor)};
	display: inline;
	font-size: 10px;
	font-weight: normal;
	line-height: 1;
	padding: 2px 4px;
	width: fit-content;
	@media ${devices.tablet} {
		font-weight: 600;
		font-size: 12px;
		padding: 4px 6px;
	}
`;
const Description = styled.p<{ fullWidth: boolean }>`
	font-size: 14px;
	margin: 0;
	margin-top: 16px;
	max-width: 100%;
	@media ${devices.tablet} {
		max-width: ${props => (props.fullWidth ? '100%' : '652px')};
	}
`;
const DescriptionWrapper = styled.div<{ fullWidth: boolean }>`
	margin-top: 20px;
	p {
		font-size: 14px;
		margin: 0;
	}
	@media ${devices.tablet} {
		margin-top: 24px;
		max-width: ${props => (props.fullWidth ? '100%' : '580px')};
	}
`;
const FeaturesContainer = styled.div`
	display: grid;
	gap: 10px;
	grid-row-gap: 18px;
	grid-template-columns: repeat(2, 1fr);
	margin-bottom: 24px;
	margin-top: 18px;
	@media ${devices.tablet} {
		grid-row-gap: 24px;
		display: flex;
		gap: 32px;
	}
`;
const Feature = styled.div`
	align-items: center;
	display: flex;
	gap: 8px;
	h3 {
		font-size: 14px;
		font-weight: 600;
		line-height: 1;
		margin: 0;
		@media ${devices.tablet} {
			font-weight: normal;
		}
	}
`;
const Content = styled.div``;
const MoreButtonContainer = styled.div`
	display: flex;
	justify-content: center;
	margin-top: 32px;
	@media ${devices.tablet} {
		margin-top: 64px;
	}
`;
const MoreButton = styled(Button)`
	align-items: center;
	display: flex;
`;
const MoreButtonText = styled.span`
	margin-right: 6px;
`;

interface CornerImage {
	src: string;
	alt: string;
	width: number;
	height: number;
}
interface HomeSectionProps {
	/**
	 * 主题，默认default
	 */
	theme?: Theme;
	/**
	 * 标题内容
	 */
	title?: string;
	/**
	 * 描述
	 */
	description?: string;
	/**
	/**
	 * 多行描述
	 */
	descriptions?: string[];
	/**
	 * 特性
	 */
	features?: string[];
	/**
	 * title 后面是否显示 hot
	 */
	hot?: boolean;
	/**
	 * 底部按钮内容
	 */
	btnTitle?: string;
	/**
	 * 底部按钮链接
	 */
	btnLink?: string;
	children?: ReactNode;
	/**
	 * 右上方图标
	 */
	cornerImage?: CornerImage;
	fullWidth?: boolean;
}

/**
 * home-section组件
 */
const HomeSection = ({
	theme = 'default',
	title = '',
	description = '',
	descriptions = [],
	features = [],
	hot = false,
	btnTitle = '',
	btnLink = '#',
	cornerImage,
	fullWidth = false,
	children
}: HomeSectionProps) => {
	const renderFeature = (text: string) => {
		return (
			<Feature key={text}>
				<Image
					src="/icon/feature.svg"
					alt="feature"
					loader={imageLoader}
					unoptimized
					width={18}
					height={18}
				/>
				<h3>{text}</h3>
			</Feature>
		);
	};

	return (
		<SectionContainer theme={theme}>
			<Section>
				{title && (
					<TitleContainer>
						<Title>
							{title}
							{hot && <Hot>HOT</Hot>}
						</Title>
					</TitleContainer>
				)}
				{description && <Description fullWidth={fullWidth}>{description}</Description>}
				{descriptions && descriptions.length > 0 && (
					<DescriptionWrapper fullWidth>
						{descriptions.map(desc => (
							<p key={desc}>{desc}</p>
						))}
					</DescriptionWrapper>
				)}
				{cornerImage?.src && (
					<ImageContainer>
						<Image
							src={cornerImage.src}
							alt={cornerImage.alt || ''}
							unoptimized
							loader={imageLoader}
							width={cornerImage.width}
							height={cornerImage.height}
						/>
					</ImageContainer>
				)}
				{features && features.length > 0 && (
					<FeaturesContainer>
						{features.map(item => renderFeature(item))}
					</FeaturesContainer>
				)}
				<Content>{children}</Content>
				{btnTitle && (
					<MoreButtonContainer>
						<Link href={btnLink}>
							<MoreButton color="beige" theme={theme}>
								<MoreButtonText>{btnTitle}</MoreButtonText>
								<Image
									src="/icon/more.svg"
									alt="more"
									width={12}
									height={8}
									loader={imageLoader}
									unoptimized
								/>
							</MoreButton>
						</Link>
					</MoreButtonContainer>
				)}
			</Section>
		</SectionContainer>
	);
};

export default HomeSection;
