import ReactPlayer from 'react-player/wistia';
import { CSSProperties } from 'styled-components';

const defaultStyle: CSSProperties = {
	position: 'relative'
};

const ReactPlayerWistia = ({ url, style }: { url: string; style?: CSSProperties }) => {
	return (
		<ReactPlayer
			width={style?.width || '100%'}
			height={style?.height || 'auto'}
			url={url}
			controls={false}
			playsinline
			playing
			loop
			style={{ ...style, ...defaultStyle }}
			config={{
				options: {
					videoFoam: true,
					/** 自动静音播放，加载后有打开声音按钮 */
					silentAutoPlay: true
				}
			}}
		/>
	);
};

export default ReactPlayerWistia;
