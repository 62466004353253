'use client';

import Image from 'next/image';
import React from 'react';
import ladderLeft from 'src/assets/images/ladder-left.png';
import ladderRight from 'src/assets/images/ladder-right.png';
import amazonLogo from 'src/assets/images/partnership-amazon.png';
import atlassianLogo from 'src/assets/images/partnership-atlassian.png';
import canvaLogo from 'src/assets/images/partnership-canva.png';
import deloitteLogo from 'src/assets/images/partnership-deloitte.png';
import styled from 'styled-components';

import { pageContainer } from '@/styles/mixin';
import { color, devices } from '@/styles/variables';
import imageLoader from '@/utils/loader';

const SectionContainer = styled.div`
	background-color: ${color.primaryDark};
	color: ${color.whiteColor};
	width: 100%;
`;

const Section = styled.div`
	${pageContainer('1440px')};
	padding: 30px 16px;
	position: relative;
	@media ${devices.tablet} {
		padding: 80px 24px;
	}
	@media ${devices.largeLaptop} {
		padding: 80px 0;
	}
`;

const Container = styled.div`
	color: black;
	display: flex;
	flex-direction: column;
`;

const ContentContainer = styled.div`
	align-items: center;
	display: flex;
	flex-direction: column;
	justify-content: center;
`;

const Title = styled.h2`
	color: ${color.whiteColor};
	font-size: 24px;
	font-weight: 600;
	height: 24px;
	letter-spacing: normal;
	line-height: 1;
	margin: 0 25px 10px;
`;

const Description = styled.p`
	color: ${color.whiteColor};
	font-size: 14px;
	letter-spacing: normal;
	line-height: 20px;
	margin: 6px 0 10px;
	max-width: 100%;
	text-align: center;
	@media ${devices.tablet} {
		max-width: 440px;
	}
	@media ${devices.laptop} {
		max-width: 744px;
	}
`;

const LogosContainer = styled.div`
	align-items: center;
	display: flex;
	flex-direction: column;
	gap: 45px;
	justify-content: center;
	margin: 50px 0 40px;

	@media ${devices.tablet} {
		gap: 72px;
		flex-direction: row;
		justify-content: space-between;
	}
`;

const CompanyLogo = styled(Image)`
	max-width: 220px;
`;

const LaddersContainer = styled.div`
	display: none;
	width: 100%;

	@media ${devices.tablet} {
		display: block;
	}
`;

const LadderImage = styled(Image)`
	position: absolute;
	top: 0;
`;

const CooperativeCompany = () => {
	return (
		<SectionContainer>
			<Section>
				<LaddersContainer>
					<LadderImage
						src={ladderLeft.src}
						alt="Ladder Left"
						width={139}
						height={157}
						style={{
							left: '20px'
						}}
						loading="lazy"
						loader={imageLoader}
					/>
					<LadderImage
						src={ladderRight.src}
						alt="Ladder Right"
						width={120}
						height={190}
						style={{
							right: '20px'
						}}
						loading="lazy"
						loader={imageLoader}
					/>
				</LaddersContainer>
				<Container>
					<ContentContainer>
						<Title>Partnership</Title>
						<Description>
							我们很自豪能够与一些全球最具影响力的科技和商业公司建立合作。这些合作伙伴关系展现了我们对卓越与创新的承诺，并为我们的学员提供了接触Professional
							Networking, 真实项目, 实习机会, 就业机会以及前沿技术的宝贵机会。
						</Description>
						<LogosContainer>
							<CompanyLogo
								src={atlassianLogo}
								alt="atlassian"
								loader={imageLoader}
								width={218}
								height={46}
								style={{
									objectFit: 'contain'
								}}
							/>
							<CompanyLogo
								src={canvaLogo}
								alt="canva"
								loader={imageLoader}
								width={115}
								height={46}
								style={{
									objectFit: 'contain'
								}}
							/>
							<CompanyLogo
								src={amazonLogo}
								alt="amazon"
								loader={imageLoader}
								width={126}
								height={46}
								style={{ objectFit: 'contain' }}
							/>
							<CompanyLogo
								src={deloitteLogo}
								alt="deloitte"
								loader={imageLoader}
								width={148}
								height={46}
								style={{ objectFit: 'contain' }}
							/>
						</LogosContainer>
					</ContentContainer>
				</Container>
			</Section>
		</SectionContainer>
	);
};

export default CooperativeCompany;
