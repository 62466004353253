import styled from 'styled-components';

import CommunityDetails from '../CommunityDetails';

import Button from '@/components/UI/Button';
import { boxElevation } from '@/styles/mixin';
import { color, devices } from '@/styles/variables';

const DoubleBoxContainer = styled.div`
	${boxElevation(8, color.bgColor)};
	background-color: ${color.whiteColor};
	border: solid 1px #000;
	color: ${color.blackColor};
	display: flex;
	flex-direction: column;
	gap: 24px;
	padding: 16px;
	p {
		margin: 0 0;
	}
	@media ${devices.tablet} {
		gap: unset;
		min-height: 284px;
	}
	@media ${devices.largeLaptop} {
		padding: 24px;
	}
`;

const ButtonContainer = styled.div`
	display: flex;
	justify-content: flex-end;
	margin-top: auto;
`;

interface PrimeCommunityProps {
	data: IFetchCareerCommunity;
	onClick: () => void;
}

const PrimeCommunity = ({ data, onClick }: PrimeCommunityProps) => {
	return (
		<DoubleBoxContainer>
			<CommunityDetails data={data} withTagsAndDescription />
			<ButtonContainer>
				<Button onClick={onClick}>申请入群</Button>
			</ButtonContainer>
		</DoubleBoxContainer>
	);
};

export default PrimeCommunity;
