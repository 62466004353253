import React from 'react';
import styled from 'styled-components';

import UserIcon from '@/assets/icons/user-icon--black.svg';
import WechatIcon from '@/assets/icons/wechat-icon.svg';

const Container = styled.div`
	align-items: center;
	display: flex;
	gap: 8px;
	p {
		font-size: 14px;
		margin: 0;
	}
`;

const Filler = styled.div`
	width: 4px;
`;

const WechatGroupHeadCount = ({ headCount }: { headCount: number }) => {
	return (
		<Container>
			<WechatIcon />
			<p>微信</p>
			{headCount && (
				<>
					<Filler />
					<UserIcon />
					<p>{`人数: ${headCount}`}</p>
				</>
			)}
		</Container>
	);
};

export default WechatGroupHeadCount;
