import Image from 'next/image';
import React from 'react';
import styled from 'styled-components';

import CommunityDetails from '../CommunityDetails';

import Button from '@/components/UI/Button';
import { color, devices } from '@/styles/variables';
import imageLoader from '@/utils/loader';

const Container = styled.div`
	display: flex;
	flex-direction: column;
	padding: 32px;
	width: 100%;
	@media ${devices.tablet} {
		min-width: 406px;
	}
`;

const QRCodeWrapper = styled.div`
	align-items: center;
	display: flex;
	flex-direction: column;
	font-size: 14px;
	gap: 10px;
	justify-content: center;
	margin-top: 16px;
`;

const ImageWrapper = styled(Image)`
	border: solid 1px ${color.blackColor};
	border-radius: 1px;
	height: 140px;
	object-fit: contain;
	width: 140px;
`;

const ButtonContainer = styled.div`
	display: flex;
	justify-content: flex-end;
	margin-top: 36px;
`;

interface Props {
	modalData?: IFetchCareerCommunity;
	handleClose: () => void;
}

const ModalContent = ({ handleClose, modalData }: Props) => {
	return (
		<Container>
			{modalData && (
				<CommunityDetails data={modalData} withTagsAndDescription showFullDescription />
			)}
			{modalData?.communityQRCode?.url && (
				<QRCodeWrapper>
					<ImageWrapper
						src={modalData.communityQRCode.url}
						alt="QRCode"
						loader={imageLoader}
						width={0}
						height={0}
						unoptimized
					/>
					<span>扫码加入群组哦～</span>
				</QRCodeWrapper>
			)}
			<ButtonContainer>
				<Button onClick={() => handleClose()} color="white">
					返回群组列表
				</Button>
			</ButtonContainer>
		</Container>
	);
};

export default ModalContent;
