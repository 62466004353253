import { Modal as MuiModal } from '@mui/material';
import { ReactNode } from 'react';
import styled from 'styled-components';

import ModalContainer from './ModalContainer';
import ModalContainerMobile from './ModalContainerMobile';

export enum ModalTheme {
	Default = 'default',
	Primary = 'primary'
}

const Container = styled(MuiModal)`
	align-items: center;
	display: flex;
	flex-direction: column;
	height: 100vh;
	justify-content: center;
	position: fixed;
	width: 100vw;
`;

interface ModalProps {
	isVisible: boolean;
	handleClose: () => void;
	title?: string;
	children: ReactNode;
	theme?: ModalTheme;
	closeOnClickOutside?: boolean;
	/** 适配移动端，为true，为新移动端布局 */
	mobile?: boolean;
	/** 移动端title */
	mobileTitle?: string;
}

/**
 * @deprecated 推荐使用@/components/UI/Modal 参考 [`Modal`](http://localhost:6006/?path=/docs/stories-ui-modal--docs)
 * @param param
 */
const Modal = ({
	isVisible,
	handleClose,
	title,
	children,
	theme = ModalTheme.Default,
	closeOnClickOutside,
	mobile = false,
	mobileTitle
}: ModalProps) => {
	return (
		<Container
			open={isVisible}
			onClose={() => {
				if (closeOnClickOutside) handleClose();
			}}
		>
			<>
				<ModalContainer
					mobile={mobile}
					title={title}
					handleClose={handleClose}
					theme={theme}
				>
					{children}
				</ModalContainer>
				{mobile && (
					<ModalContainerMobile
						mobile={mobile}
						title={mobileTitle}
						handleClose={handleClose}
					>
						{children}
					</ModalContainerMobile>
				)}
			</>
		</Container>
	);
};

export default Modal;
