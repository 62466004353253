import { isEmpty } from 'lodash';
import styled from 'styled-components';

import Avatar from './Avatar';
import Badge from './Badge';
import WechatGroupHeadCount from './WechatGroupHeadCount';
import { textEllipsis } from '@/styles/mixin';
import { color, devices } from '@/styles/variables';

const { whiteColor, greyBackgroundColor } = color;

const Container = styled.div`
	background-color: ${whiteColor};
	display: flex;
	flex-direction: column;
	gap: 24px;
	justify-content: center;
`;

const BriefWrapper = styled.div`
	display: flex;
	gap: 12px;
	@media ${devices.tablet} {
		gap: 24px;
	}
`;

const TextWrapper = styled.div<{ withTagsAndDescription: boolean; hasTags: boolean }>`
	display: flex;
	flex-direction: column;
	gap: 8px;
	justify-content: ${props =>
		props.withTagsAndDescription ? props.hasTags && 'space-between' : 'space-around'};
`;

const TitleContainer = styled.div`
	align-items: center;
	display: flex;
	gap: 8px;
`;

const Title = styled.div`
	${textEllipsis()};
	font-size: 16px;
	font-weight: 600;
	@media ${devices.tablet} {
		font-size: 18px;
	}
`;

const Tags = styled.div`
	display: flex;
	flex-wrap: wrap;
	gap: 8px;
	height: 20px;
	overflow: hidden;
`;

const Tag = styled.p`
	background-color: ${greyBackgroundColor};
	font-size: 12px;
	margin: 0;
	padding: 2px 6px;
`;

const Description = styled.p<{ showFullDescription: boolean }>`
	font-size: 12px;
	margin: 0;
	@media ${devices.tablet} {
		${props => !props.showFullDescription && textEllipsis(3)};
	}
`;

interface Props {
	data?: IFetchCareerCommunity;
	withTagsAndDescription?: boolean;
	showFullDescription?: boolean;
}

const CommunityDetails = ({
	data,
	withTagsAndDescription = false,
	showFullDescription = false
}: Props) => {
	if (!data) return null;
	const { avatar, name, badge, headCount, tags, description } = data;

	return (
		<Container>
			<BriefWrapper>
				{avatar?.url && (
					<Avatar src={avatar.url} size={withTagsAndDescription ? '90px' : '70px'} />
				)}
				<TextWrapper
					withTagsAndDescription={withTagsAndDescription}
					hasTags={!isEmpty(tags)}
				>
					<TitleContainer>
						<Title>{name}</Title>
						<Badge badgeName={badge} />
					</TitleContainer>
					<WechatGroupHeadCount headCount={headCount} />
					{withTagsAndDescription && !isEmpty(tags) && (
						<Tags>
							{tags?.map(item => (
								<Tag key={item}>{item}</Tag>
							))}
						</Tags>
					)}
				</TextWrapper>
			</BriefWrapper>
			{withTagsAndDescription && description && (
				<Description showFullDescription={showFullDescription}>{description}</Description>
			)}
		</Container>
	);
};

export default CommunityDetails;
