import Image from 'next/image';
import React from 'react';
import styled from 'styled-components';

import { color } from '@/styles/variables';
import imageLoader from '@/utils/loader';

const { blackColor } = color;

const ImageContainer = styled(Image)`
	border: solid 1px ${blackColor};
	border-radius: 1px;
	height: ${props => props.theme.size || '70px'};
	object-fit: contain;
	width: ${props => props.theme.size || '70px'};
`;

const Avatar = ({ src, size }: { src: string; size?: string }) => {
	return (
		<ImageContainer
			src={src}
			theme={{ size }}
			alt="Avatar"
			loader={imageLoader}
			width={0}
			height={0}
			unoptimized
		/>
	);
};

export default Avatar;
