import Image from 'next/image';
import { ReactNode } from 'react';
import styled from 'styled-components';

import { devices } from '@/styles/variables';
import imageLoader from '@/utils/loader';

const Container = styled.div<{ mobile: boolean }>`
	background-color: #fff;
	display: ${({ mobile }) => (mobile ? 'block' : 'none')};
	height: 100vh;
	width: 100vw;
	@media ${devices.laptop} {
		display: none;
	}
`;
const Header = styled.div`
	align-items: center;
	display: flex;
	height: 58px;
	justify-content: space-between;
	padding: 16px;
	width: 100%;
`;
const HeaderInfo = styled.div`
	align-items: center;
	display: flex;
`;
const HeaderInfoSplit = styled.div`
	background-color: #000;
	height: 14px;
	margin: 0 16px;
	width: 1px;
`;
const HeaderInfoTitle = styled.p`
	font-size: 16px;
	font-weight: 600;
	line-height: 1;
`;
const Content = styled.div`
	height: calc(100vh - 58px);
	overflow-y: scroll;
`;

const ModalContainerMobile = ({
	children,
	handleClose,
	mobile,
	title
}: {
	children: ReactNode;
	handleClose: () => void;
	mobile: boolean;
	title?: string;
}) => {
	return (
		<Container mobile={mobile}>
			<Header>
				<HeaderInfo>
					<Image
						src="/image/logo.jpg"
						alt="logo"
						unoptimized
						loader={imageLoader}
						width={24}
						height={24}
					/>
					{title && <HeaderInfoSplit />}
					{title && <HeaderInfoTitle>{title}</HeaderInfoTitle>}
				</HeaderInfo>
				<Image
					onClick={() => handleClose()}
					src="/icon/close.svg"
					alt="close"
					width={14}
					height={14}
					unoptimized
					loader={imageLoader}
					style={{
						margin: '6px'
					}}
				/>
			</Header>
			<Content>{children}</Content>
		</Container>
	);
};

export default ModalContainerMobile;
